import request from '@/utils/request.js';
const BASEURL = process.env.VUE_APP_BASE_URL;

export function SPONSOR(params) {
  return request({
    url: BASEURL + '/home/web/sponsor',
    method: 'POST',
    params,
  });
}
export function PARTNER(params) {
  return request({
    url: BASEURL + '/home/web/partner',
    method: 'POST',
    params,
  });
}
