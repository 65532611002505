<template>
  <div class="partenr_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/spons.png" />
      </div>
    </div>
    <div class="tips1">
      <div class="container content">
        <div class="title_">2022 Sponsors</div>
        <img class="part" src="@/assets/images/spons_2.png" />
      </div>
    </div>
    <div class="tips2">
      <div class="container content">
        <div class="text_">
          At ECI, we believe in we grow together in community.
        </div>
        <div class="title_">Sponsorship Benefits</div>
        <ul class="part_list">
          <li>
            <div class="p1">Brand<br />Awareness</div>
          </li>
          <li>
            <div class="p1">Collaboration</div>
          </li>
          <li>
            <div class="p1">Business<br />Networking</div>
          </li>
          <li>
            <div class="p1">Teaching<br />and Learning</div>
          </li>
        </ul>
        <div class="text_">
          Please contact us by email to have more information in detail.
        </div>
      </div>
    </div>
    <div class="tips3">
      <div class="container content">
        <div class="title_">
          <span>Please fill in the form below</span>
          <br />Want to be our sponsor
        </div>
        <div class="part_form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item
              label="First Name:"
              prop="first_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.first_name"></el-input>
            </el-form-item>
            <el-form-item
              label="Last Name:"
              prop="last_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.last_name"></el-input>
            </el-form-item>
            <el-form-item label="Emai:" prop="email" label-width="150px">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item
              label="Company:"
              prop="company_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.company_name"></el-input>
            </el-form-item>
            <el-form-item label="Country:" prop="country" label-width="150px">
              <el-input v-model="ruleForm.country"></el-input>
            </el-form-item>

            <el-form-item
              label="Your Message:"
              prop="message"
              class="textarea"
              label-width="150px"
            >
              <el-input type="textarea" v-model="ruleForm.message"></el-input>
            </el-form-item>
            <div class="button" @click="submitForm('ruleForm')">SUBMIT</div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="backBtn" @click="pageBack" style="margin: 50px auto"></div>
  </div>
</template>
<script>
import { SPONSOR } from '@/api/join.js';
export default {
  name: 'World',
  components: {},
  data() {
    return {
      ruleForm: {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        country: '',
        message: '',
      },
      rules: {
        first_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        email: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        company_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        country: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        message: [{ required: true, message: 'Please enter', trigger: 'blur' }],
      },
    };
  },
  created() {},
  methods: {
    pageBack() {
      this.$router.go(-1);
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          SPONSOR(this.ruleForm).then(res => {
            console.log(res);
            if (res.errcode == 0) {
              this.$message({
                type: 'success',
                message: 'success',
              });
              this.ruleForm = {
                first_name: '',
                last_name: '',
                email: '',
                company_name: '',
                country: '',
                message: '',
              };
            } else {
              this.$message({
                type: 'warning',
                message: res.errmsg,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/part.scss';
</style>
